import React from "react";
import "./App.css";
import { BrowserRouter,Switch, Route , NavLink} from 'react-router-dom'
import About from "./components/About";
import Home from "./components/Home";
import Impressum from "./components/Impressum";
import Datenschutz from "./components/Datenschutz";

export default function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/impressum">
          <Impressum />
        </Route>
        <Route path="/datenschutz">
          <Datenschutz />
        </Route>
      </Switch>
      <div className="daten">
      <NavLink to="/impressum">Impressum</NavLink>
      <NavLink to="/datenschutz">Privacy Policy</NavLink>
      </div>
    </BrowserRouter>
  );
}