import React from 'react'
import Homescreen from "../images/homescreen.png";
import Goalscreen from "../images/goalscreen.png";
import Categories from "../images/categories.png";
import PersonIcon from '@material-ui/icons/Person';
import {NavLink} from 'react-router-dom'
//Material UI
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import ImportExportIcon from '@material-ui/icons/ImportExport';

export default function About() {
    return (
        <div className="about">
            <div className="navigationabout">
            <nav id="burger">
            <ul>
              <li>
              <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <NavLink to="/about">About Xallet</NavLink>
              </li>
              <li>
                <a href="mailto:info.exude@gmail.com">Contact</a>
              </li>
            </ul>
          </nav>
            </div>
            <h1 style={{fontSize: "40px", textAlign: "center", color: "white", margin: "4rem 0"}}> ABOUT <br /> <span style={{fontStyle: "italic"}}>EXUDE XALLET</span> </h1>
            <div className="aboutinfo">
            <div className="single">
                <img src={Homescreen} alt="home" className="Exude-Logo" />
                <div className="detail">
                <PersonIcon style={{fontSize: "50px"}} />
                <h2>100% USER-FRIENDLY</h2>
                <p>This app is so easy to use. It gives you an up to the minute view of your income, including your cash on hand and your bank balance. Your financial goals stay front and center along with every transaction you make.</p>
                </div>
                </div>
                <div className="single second">
                <img src={Categories} alt="categories" className="Exude-Logo" />
                <div className="detail">
                <ImportExportIcon style={{fontSize: "50px"}} />
                <h2>SEE ALL TRANSACTIONS AT A GLANCE</h2>
                <p>Always have a perfect overview over your Income, Expenses and your current Goals. The App shows you every Income or Expense by detail. </p>
                </div>
                </div>
                <div className="single third">
                <img src={Goalscreen} alt="nolimimts" className="Exude-Logo" />
                <div className="detail">
                <RemoveRedEyeIcon style={{fontSize: "50px"}} />
                <h2>NO LIMITS OR RESTRICTIONS</h2>
                <p>This app is designed for use with a big business, a small business, a Freelancer or even just for your private use. The App has no limits or restrictions.  You use it to achieve your goals, one day at a time.</p>
                </div>
                </div>
            </div>
        </div>
    )
}
