import React from 'react'
//Image Head
import Homescreen from "../images/homescreen.png";
import Goalscreen from "../images/goalscreen.png";
import Expensescreen from "../images/expensescreen.png";
import Categories from "../images/categories.png";
//Icons
import AppleIcon from '@material-ui/icons/Apple';
import AndroidIcon from '@material-ui/icons/Android';
import InstagramIcon from '@material-ui/icons/Instagram';
import PersonIcon from '@material-ui/icons/Person';
//Material UI
import Menu from './Menu'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import {NavLink} from 'react-router-dom'

function handelopen() {
  const burger = document.getElementById("burger");
  burger.classList.toggle("active");
}

export default function Home() {
    return (
        <>
        <div className="app-wrapper">
      <main className="main-wrapper">
        <section>
          <h2> Exude - Xallet</h2>
          <Menu />
          <nav id="burger">
            <ul>
              <li>
              <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <NavLink to="/about">About Xallet</NavLink>
              </li>
              <li>
                <a href="mailto:info.exude@gmail.com">Contact</a>
              </li>
            </ul>
          </nav>
        </section>
        <div className="content">
          <div className="block1">
            <h1 style={{ color: "white" }}>
              <span className="line">-</span> The best virtual wallet <br /> you can find
            </h1>
            <p>
            Manage your income and expenses <br /> on the most effective way
            </p>
            <a className="contact" href="mailto:info.exude@gmail.com">Contact Us</a>
          </div>
          <div className="shower">
            <img src={Homescreen} alt="home" className="Exude-Logo" />
            <img src={Expensescreen} alt="expense" className="Exude-Logo" />
            <img src={Goalscreen} alt="goals" className="Exude-Logo" />
          </div>
        </div>
        <footer>
          <h2 className="whoisthemaster">Author: Exude</h2>
          <div className="icons">
            <a href="https://www.apple.com/"><AppleIcon style={{color: "white"}} /></a>
            <a href="https://play.google.com/store/apps?utm_source=emea_Med&utm_medium=hasem&utm_content=Sep2020&utm_campaign=Evergreen&pcampaignid=MKT-EDR-emea-de-1001280-Med-hasem-ap-Evergreen-Sep2020-Text_Search_SKWS%7cONSEM_kwid_43700049312855586&gclid=Cj0KCQiA3smABhCjARIsAKtrg6KhGrUK5Cqs1BlxDEn5evwq0Yoz969gHOn-d8-P5yFzcJhOTlE-JssaAvErEALw_wcB&gclsrc=aw.ds"><AndroidIcon style={{color: "white"}} /></a>
            <a href="https://www.instagram.com/exude.social/"><InstagramIcon style={{color: "white"}} /></a>
          </div>
        </footer>
      </main>
    </div>
    <div className="mobilediv">
      <div className="arrowdiv">
      <p className="arrow" style={{margin: "0", display: "flex", flexDirection: "column", alignItems: "center", fontSize: "20px"}}>Scroll to view App<ArrowDownwardIcon /></p>
      </div>
      <div className="single">
        <img src={Homescreen} className="Exude-Logo" />
        <PersonIcon style={{fontSize: "50px"}} />
        <h2>100% USER-FRIENDLY</h2>
        <p>This app is so easy to use. It gives you an up to the minute view of your income, including your cash on hand and your bank balance. Your financial goals stay front and center along with every transaction you make.</p>
        </div>
        <div className="single">
        <img src={Categories} className="Exude-Logo" />
        <ImportExportIcon style={{fontSize: "50px"}} />
        <h2>SEE ALL TRANSACTIONS AT A GLANCE</h2>
        <p>Always have a perfect overview over your Income, Expenses and your current Goals. The App shows you every Income or Expense by detail. </p>
        </div>
        <div className="single">
        <img src={Goalscreen} className="Exude-Logo" />
        <RemoveRedEyeIcon style={{fontSize: "50px"}} />
        <h2>NO LIMITS OR RESTRICTIONS</h2>
        <p>This app is designed for use with a big business, a small business, a Freelancer or even just for your private use. The App has no limits or restrictions.  You use it to achieve your goals, one day at a time.</p>
        </div>    
    </div>
        </>
    )
}
